<template>
  <div class="project-image-gallery">
    <!--[START] Block -->
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item" v-for="(image, key) in images" v-bind:class="{ 'active': key === currentItem }" v-bind:key="key">
          <img v-bind:data-src="image.uncroppedImage.srcWebp" v-bind:src="image.uncroppedImage.placeholderImage" v-bind:srcset="image.uncroppedImage.srcsetWebp" class="img-fluid d-block w-100" alt="image">
          <p class="carousel-slide-counter"><small>{{key+1}}/{{images.length}}</small></p>
        </div>
        <!-- add active to first-->
      </div>
      <a class="carousel-control-prev" role="button" data-slide="prev" v-on:click="previousBtnAction()" v-on:keyup.left="previousBtnAction()">
        <span class="carousel-control-prev-icon carousel-btn" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" role="button" data-slide="next" v-on:click="nextBtnAction()" v-on:keyup.right="nextBtnAction()">
        <span class="carousel-control-next-icon carousel-btn" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <!--[END] Block -->
  </div>
</template>
<script>
  export default {
    name: 'ProjectImageGallery',
    props: {
      images: Array
    },
    data () {
      return {
        currentItem: 0
      }
    },
    methods: {
      initGallery: function () {
        // Initiates the Gallery Function
        console.log('initiate gallery')
      },

      previousBtnAction: function () {
        if (this.currentItem === 0) {
          this.currentItem = this.images.length - 1
        } else {
          this.currentItem = this.currentItem - 1
        }
      },

      nextBtnAction: function () {
        console.log('next btn clicked')
        if (this.currentItem === this.images.length - 1) {
          this.currentItem = 0
        } else {
          this.currentItem = this.currentItem + 1
        }
      }
    },
    mounted () {
      console.log('Gallery Modual Loaded')
    }
  }
</script>
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "src/assets/scss/mixins.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  .project-image-gallery{
    .carousel-control-prev, .carousel-control-next{
      opacity: 1.0;
      width: 70px;
    }
    .carousel-control-prev-icon{
      margin-left:-300%;
    }
    .carousel-control-next-icon{
      margin-right:-300%;
    }
    @media only screen and (max-width: 1296px) {
      .carousel-control-prev-icon{
        margin-left:-100%;
      }
      .carousel-control-next-icon{
        margin-right:-100%;
      }
    }
    @include media-breakpoint-down(lg) {
      .carousel-control-prev-icon{
        margin-left:-70px;
      }
      .carousel-control-next-icon{
        margin-right:-70px;
      }

    }
    .carousel-btn{
      @include transition(all 0.25s ease);
      width: 48px;
      height: 48px;
      border-radius: 52px;
      background-color:$primary-color;
      background-size: 35% 35%;
      bottom: -12px;
    }
    .carousel-btn:hover{
      background-size: 25% 25%;
      background-color: black;
      border:#ffffff 0px solid;
    }
    .carousel-btn:active{
      background-size: 45% 45%;
      background-color: black;
    }
    .carousel-slide-counter{
      position: absolute;
      bottom: 0px;
      margin-bottom: -5px;
      width: 100%;
      text-align: center;
      color: #666;
      font-weight: bold;
    }
    @include media-breakpoint-down(md) {
      .carousel-control-prev-icon{
        margin-left:0px;
      }
      .carousel-control-next-icon{
        margin-right:0px;
      }
    }
  }
</style>
